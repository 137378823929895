
















import {Component, Prop, Vue, Watch} from "vue-property-decorator";
import {DateTime} from "luxon";

@Component
export default class DatePickerComponent extends Vue {
    @Prop() readonly value!: DateTime // propiedad value es la propiedad v-model del componente
    @Prop() readonly label!: String

    @Prop() readonly requiredRuleText!: string
    @Prop() readonly maxRuleText!: string
    @Prop() readonly minRuleText!: string

    @Prop() readonly maxDate!: DateTime
    @Prop() readonly minDate!: DateTime
    @Prop({ default: false }) readonly readonly!: boolean

    dateTime: DateTime | null = this.value
    enabled = false

    pickerDate: string = ""

    dateRules = [
        (v: string) => !this.requiredRuleText || v ? true : this.requiredRuleText,
        (v: string) => !this.maxDate || !this.maxRuleText || !this.value || this.value < this.maxDate ? true : this.maxRuleText,
        (v: string) => !this.minDate || !this.minRuleText || !this.value || this.value > this.minDate ? true : this.minRuleText
    ]

    created() {
        this.pickerDate = this.getPickerDate()
    }

    getPickerDate() {
        const now: DateTime = DateTime.local()
        if (!this.maxDate) return now.toISODate()
        const max: DateTime = this.maxDate.minus({days: 1})
        return now > max ? max.toISODate() : now.toISODate()
    }

    @Watch("maxDate")
    watchMaxDate() {
        this.pickerDate = this.getPickerDate()
    }

    get max() {
        return this.maxDate ? this.maxDate.minus({days: 1}).toISODate() : "2099-01-01"
    }

    get min() {
        return this.minDate ? this.minDate.plus({days: 1}).toISODate() : "1940-01-01"
    }

    get dateString() {
        return this.value ? this.value.toLocaleString(DateTime.DATE_FULL) : ""
    }

    set dateString(value: string) {
        if (!value) {
            this.dateTime = null
            this.changeDate()
        }
    }

    get date() {
        return this.value ? this.value.toISODate() : null
    }

    set date(value: any) {
        this.dateTime = DateTime.fromISO(value)
    }

    changeDate() {
        this.$emit('input', this.dateTime) // envia el valor interno al v-model (value) del que utiliza este componente
    }
}
